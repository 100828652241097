var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container beauty-scroll" },
    [
      _c(
        "div",
        { staticClass: "unit" },
        [
          _c(
            "a-radio-group",
            {
              model: {
                value: _vm.title,
                callback: function ($$v) {
                  _vm.title = $$v
                },
                expression: "title",
              },
            },
            [
              _c("a-radio-button", { attrs: { value: "1" } }, [
                _c("svg", { staticClass: "iconpark-icon" }, [
                  _c("use", { attrs: { href: "#rongqi" } }),
                ]),
              ]),
              _c("a-radio-button", { attrs: { value: "2" } }, [
                _c("svg", { staticClass: "iconpark-icon" }, [
                  _c("use", { attrs: { href: "#list-middle" } }),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm.title === "1"
        ? _c(
            "Calendar",
            {
              ref: "calendar",
              staticClass: "calender-box",
              attrs: {
                "start-date": _vm.calendarStartDate,
                "end-date": _vm.calendarEndDate,
              },
              on: { onChange: _vm.changeTime },
              scopedSlots: _vm._u(
                [
                  {
                    key: "rightContent",
                    fn: function () {
                      return undefined
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                1563199808
              ),
            },
            [
              _c("template", { slot: "bottomContent" }, [
                _c(
                  "div",
                  {
                    staticClass: "content-btn",
                    on: {
                      click: function ($event) {
                        return _vm.getChirldNode($event, "")
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        class: _vm.active === "" ? "active" : "",
                        on: {
                          click: function ($event) {
                            return _vm.tabs()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "全部 " +
                            _vm._s(_vm.vacationTypeList.allNum || 0) +
                            " 人"
                        ),
                      ]
                    ),
                    _vm._l(
                      _vm.vacationTypeList.vacationData,
                      function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            class:
                              _vm.active === item.vacationTypeId
                                ? "active"
                                : "",
                            on: {
                              click: function ($event) {
                                return _vm.tabs(item.vacationTypeId)
                              },
                            },
                          },
                          [
                            _c("span", {
                              staticClass: "text-color",
                              style:
                                "backgroundColor:rgba(" + item.fontRgba + ")",
                            }),
                            _c("span", [
                              _vm._v(
                                _vm._s(item.vacationTypeName) +
                                  " · " +
                                  _vm._s(item.num || 0) +
                                  " 人"
                              ),
                            ]),
                          ]
                        )
                      }
                    ),
                  ],
                  2
                ),
              ]),
              _vm._l(_vm.calendarList, function (item) {
                return _c(
                  "template",
                  {
                    staticStyle: { width: "100%", position: "relative" },
                    slot: item.calendarDate,
                  },
                  [
                    _c(
                      "div",
                      {
                        key: item.calendarDate,
                        staticClass: "events",
                        class: item.vacation.length > 3 ? "events-active" : "",
                      },
                      _vm._l(item.vacation, function (col) {
                        return _c(
                          "div",
                          { key: col.vacationTypeId },
                          [
                            _c(
                              "a-popover",
                              {
                                attrs: {
                                  placement: "bottom",
                                  trigger: "click",
                                },
                              },
                              [
                                _c("template", { slot: "title" }, [
                                  _c("div", { staticClass: "header-box" }, [
                                    _c("div", {
                                      staticClass: "color_box",
                                      style:
                                        "background:rgba(" + col.fontRgba + ")",
                                    }),
                                    _c("div", { staticClass: "title" }, [
                                      _vm._v(
                                        " " + _vm._s(col.vacationTypeName) + " "
                                      ),
                                    ]),
                                  ]),
                                ]),
                                _c("template", { slot: "content" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "content-box beauty-scroll",
                                    },
                                    _vm._l(
                                      col.staffApplyVacation,
                                      function (events) {
                                        return _c(
                                          "div",
                                          {
                                            key: events.staffId,
                                            staticClass: "contentDetail",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "personnel-information",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "img" },
                                                  [
                                                    _c("CbTableAvater", {
                                                      attrs: {
                                                        "show-user-name": false,
                                                        "avater-name":
                                                          events.staffName,
                                                        "on-job-id":
                                                          events.onJobId,
                                                        "staff-id":
                                                          events.staffId,
                                                        size: "40",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "Information",
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(events.staffName)
                                                      ),
                                                    ]),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(events.orgName) +
                                                          "/" +
                                                          _vm._s(
                                                            events.postName
                                                          )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c("div", { staticClass: "date" }, [
                                              _c(
                                                "span",
                                                { staticClass: "title" },
                                                [_vm._v("开始时间")]
                                              ),
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("invert")(
                                                    events.startDate
                                                  )
                                                ) +
                                                  " " +
                                                  _vm._s(
                                                    events.vacationTypeUnit ===
                                                      "H"
                                                      ? events.startTime
                                                      : ""
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                            _c("div", { staticClass: "date" }, [
                                              _c(
                                                "span",
                                                { staticClass: "title" },
                                                [_vm._v("结束时间")]
                                              ),
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("invert")(
                                                    events.endDate
                                                  )
                                                ) +
                                                  " " +
                                                  _vm._s(
                                                    events.vacationTypeUnit ===
                                                      "H"
                                                      ? events.endTime
                                                      : ""
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                            _c("div", { staticClass: "date" }, [
                                              _c(
                                                "span",
                                                { staticClass: "duration" },
                                                [_vm._v("时长")]
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    events.vacationTypeUnit ===
                                                      "D"
                                                      ? events.workDays + "天"
                                                      : events.workHours +
                                                          "小时"
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass: "badge",
                                    style:
                                      "background:rgba(" +
                                      col.backgroundRgba +
                                      ")",
                                  },
                                  [
                                    _c("div", {
                                      staticClass: "color_box",
                                      style:
                                        "background:rgba(" + col.fontRgba + ")",
                                    }),
                                    _c(
                                      "p",
                                      {
                                        staticClass: "literals",
                                        attrs: {
                                          title:
                                            "" +
                                            col.vacationTypeName +
                                            col.vacationNum +
                                            "人",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(col.vacationTypeName) +
                                            " " +
                                            _vm._s(col.vacationNum) +
                                            "人"
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ]
                )
              }),
            ],
            2
          )
        : _vm._e(),
      _vm.title === "2" ? _c("Leave") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }